const state = {
  selectedAccount: 'Instagram',
  audienceFilter: {},
  influencerFilter: {},
  validSearch: 0,
  results: [],
}

const getters = {
  selectedAccount: (state) => state.selectedAccount,
  audienceFilter: (state) => state.audienceFilter,
  influencerFilter: (state) => state.influencerFilter,
  validSearch: (state) => state.validSearch,
  results: (state) => state.results,
}

const mutations = {
  setSelectedAccount(state, payload) {
    state.selectedAccount = payload.value
  },
  setAudienceFilter(state, payload) {
    state.audienceFilter = payload.value
  },
  setInfluencerFilter(state, payload) {
    state.influencerFilter = payload.value
  },
  setValidSearch(state, payload) {
    state.validSearch = payload
  },
  setResults(state, payload) {
    state.results = payload
  },
}

const actions = {
  setSelectedAccount({ commit, data }) {
    commit('setSelectedAccount', data)
  },
  setValidSearch({ commit }, data) {
    commit('setValidSearch', data)
  },
  setResults({ commit }, data) {
    commit('setResults', data)
  },
}

const searchModule = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default searchModule
