<template>
  <CSidebar
    v-if="showHeader"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="setSidebarVisible"
    class="dark"
  >
    <CSidebarBrand class="dark">
      <div class="brand-position">
        <div class="navlogo" @click="navigate('/home')">
          <img src="@/assets/brand/go.png" alt="Company Logo" />
        </div>
        <!-- <span class="brand" :class="{ 'hidden-brand': !sidebarUnfoldable }" v-if="!sidebarUnfoldable"> goFlurry</span>
        <CSidebarToggler
          class="d-none d-lg-flex"
          @click='toggleUnfoldable'
        /> -->
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { mapState } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { mapGetters } from 'vuex'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    return {
      ...mapState('common', ['sidebarUnfoldable', 'sidebarVisible']),
    }
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
    }),
    showHeader() {
      // STATE is not persisting, it reset on reload of page.
      return this.isLoggedIn || localStorage.getItem('login')
    },
  },
  methods: {
    toggleUnfoldable() {
      console.log(this.$store.state.sidebarVisible)
      this.$store.commit('common/toggleUnfoldable') // Use the sidebar module's mutation
    },
    setSidebarVisible(event) {
      this.$store.commit('common/updateSidebarVisible', event) // Use the sidebar module's mutation
    },
    navigate(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style>
.brand {
  color: var(--white);
  font-size: 1.5rem;
}

.brand-position {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.dark {
  background-color: var(--black1);
  z-index: 1;
}

.navlogo {
  padding: 5px;
  cursor: pointer;
}
.navlogo img {
  height: 40px;
}
</style>
