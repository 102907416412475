<template>
  <header class="theader" v-if="showHeader">
    <div class="tlogo">
      {{ getheading }}
    </div>
    <!-- <nav class="tnavigation">
      <ul>
        <li @click="navigate('/home')">Home</li>
      </ul>
    </nav> -->
    <CHeaderNav>
      <div class="userplan">
        <div class="notif" @click="openNotifTab()">
          <Icon icon="ph:bell-fill" height="25" />
          <a v-if="getLive()" class="notification-badge"></a>
        </div>
        <div class="plantext">Plan:</div>
        <div class="planbutton">Free</div>
        <div class="plantext">Search:</div>
        <div class="planbutton">{{ search }}</div>
      </div>
      <AppHeaderDropdownAccnt />
    </CHeaderNav>
  </header>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { mapGetters } from 'vuex'
import { Icon } from '@iconify/vue'
import { getConversationsClient } from '@/services/chatClient.js'
import twilioServices from '@/services/twilio.js'
import utilServices from '@/services/util'

export default {
  name: 'AppHeader',
  components: {
    Icon,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      unreadNotif: false,
      brandId: '',
      total: 0,
    }
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapGetters('search', {
      validSearch: 'validSearch',
    }),
    showHeader() {
      // STATE is not persisting, it reset on reload of page.
      return this.isLoggedIn || localStorage.getItem('login')
    },
    search() {
      if (this.validSearch === 0) {
        return localStorage.getItem('search')
      }
      return this.validSearch
    },
    getheading() {
      let r = this.$route.path
      if (r === '/home') {
        return 'Discovery'
      } else if (r.includes('/influencer')) {
        return 'Unlocked Influencers'
      } else if (r.includes('/campaign')) {
        return 'Campaigns'
      } else if (r.includes('/program')) {
        return 'Programs'
      } else if (r.includes('/chat')) {
        return 'Chat'
      } else if (r.includes('/notification')) {
        return 'Notification'
      } else {
        return 'Discovery'
      }
    },
  },
  mounted() {
    this.brandId = localStorage.getItem('brandId')
    let isLoggedIn = localStorage.getItem('login')
    if (isLoggedIn && isLoggedIn === 'true') {
      this.getNotif()
    }
  },
  methods: {
    openNotifTab() {
      this.sendReceived()
      this.$router.push('/notification')
    },
    getNotif: async function () {
      try {
        const notifChannel = 'notif-' + this.brandId
        const conversationsClient = await getConversationsClient()
        const conversation =
          await conversationsClient.getConversationByUniqueName(notifChannel)
        this.activeConversation = conversation
        this.total = conversation.lastMessage?.index
        this.getMessages()
        if (conversation) {
          this.activeConversationListener = conversation.on(
            'messageAdded',
            (message) => {
              if (message.author === 'admin') {
                this.unreadNotif = true
              } else {
                this.unreadNotif = false
              }
            },
          )
        }
      } catch (error) {
        console.log('notif-' + this.brandId, error)
        this.createNotifChannel()
      }
    },
    createNotifChannel() {
      let form = {
        name: 'notif-' + this.brandId,
        userIdentity: localStorage.getItem('email'),
      }
      twilioServices
        .createConversation(form)
        .then((response) => {
          console.log('createConversation', response.data.conversation)
          let form = {
            notifSid: response.data.conversation.sid,
          }
          this.updateNotifChannel(form)
          this.getNotif()
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    updateNotifChannel(form) {
      utilServices
        .updateNotifChannel(form)
        .then((response) => {
          console.log('response', response.data)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getLive() {
      return this.unreadNotif
    },
    getMessages: async function () {
      try {
        const response = await this.activeConversation.getMessages(
          10,
          this.total,
        )
        const messages = response.items.sort((a, b) => {
          return b.dateCreated - a.dateCreated
        })
        if (messages.length > 0) {
          if (messages[0].author === 'admin') {
            this.unreadNotif = true
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    sendReceived: async function () {
      try {
        const notifChannel = 'notif-' + this.brandId
        const conversationsClient = await getConversationsClient()
        const conversation =
          await conversationsClient.getConversationByUniqueName(notifChannel)
        const message = await conversation.prepareMessage()
        message.setBody('Received')
        await message.build().send()
      } catch (error) {
        console.error('Error sending messages:', error)
      }
    },
  },
}
</script>

<style scoped>
.theader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--black2);
  border-bottom: 1px solid var(--black2);
  color: #ffffff;
  /* position: fixed */
}
.tnavigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tnavigation ul li {
  margin: 10px 60px 0 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.tlogo {
  font-size: 28px;
  padding: 0px 0px 0px 20px;
}

.userplan {
  display: flex;
  margin: 0 10px 0 0;
}
.planbutton {
  padding: 3px 10px;
  background-color: var(--logo);
  color: var(--white);
  border: none;
  border-radius: 10px;
}
.plantext {
  padding: 3px 10px 3px 20px;
}
.notif {
  cursor: pointer;
  margin: 3px 10px;
}
.notification-badge {
  position: absolute;
  top: -1;
  left: 1;
  width: 15px;
  height: 15px;
  background-color: var(--red);
  border-radius: 50%;
  content: '';
}
</style>
