<template>
  <router-view />
  <notifications />
</template>

<!-- Import css for  multiselect dropdown-->
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
// Import Main styles for this application
:root {
  --logo: #834aff; //this is for all buttons and main color
  --white: #ffffff;
  --grey1: #efefef;
  --grey2: #686868;
  --grey3: #969697;
  --black2: #212123;
  --black1: #131315;
  --black: #000000;
  --green: #61c191;
  --green2: #4db251;
  --yellow: #ffc94f;
  --red: #ff5c50;
}
@import 'styles/style';
</style>
