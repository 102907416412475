export default [
  {
    component: 'CNavItem',
    name: 'Discovery',
    to: '/home',
    icon: 'cilMagnifyingGlass',
  },
  {
    component: 'CNavItem',
    name: 'Influencers',
    to: '/influencers',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'Campaigns',
    to: '/campaigns',
    icon: 'cil-volume-high',
  },
  {
    component: 'CNavItem',
    name: 'T-Sheet',
    to: '/tsheet',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavItem',
    name: 'Chat',
    to: '/chat',
    icon: 'cil-chat-bubble',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Conversation',
  //   to: '/conversation',
  //   icon: 'cil-chat-bubble',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Payments',
  //   to: '/payments',
  //   icon: 'cil-cash',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Analytics',
  //   to: '/analytics',
  //   icon: 'cil-chart-pie',
  // },
]
