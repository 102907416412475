import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import env from '@/env.js'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import VueMultiselect from 'vue-multiselect'
import { iconsSet as icons } from '@/assets/icons'
import Notifications from '@kyvg/vue3-notification'
import tooltip from '@/components/common/tooltip.js'
import '@/styles/tooltip.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Notifications)
app.use(vue3GoogleLogin, {
  clientId: env.VUE_APP_GOOGLE_CLIENT_ID,
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('VueMultiselect', VueMultiselect)
app.component('VueDatePicker', VueDatePicker)
app.directive('tooltip', tooltip)

app.mount('#app')

let publicRoute = ['/login', '/register']

router.beforeEach((to, from, next) => {
  console.log('Router', to)
  if (localStorage.getItem('login')) {
    // check login
    console.log('Router logged in')
    if (publicRoute.includes(to.path)) {
      //if already logged in
      next({
        path: '/home',
      })
    } else if (to.path.includes('admin')) {
      if (localStorage.getItem('admin')) {
        next()
      } else {
        next({
          path: '/home',
        })
      }
    } else {
      // TODO remove this after adding permission on each page
      next()
    }
  } else {
    // if logged out
    console.log('Router not logged in 2', to)
    if (publicRoute.includes(to.path)) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      }) // make sure to always call next()!
    }
  }
})
