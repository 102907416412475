import { apiClientSecure } from './client'

export default {
  getTwilioAccessToken(user) {
    return apiClientSecure.get('/twilio/token?id=' + user)
  },
  createConversation(form) {
    return apiClientSecure.post('/twilio/conversation', { form: form })
  },
}
