import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/Index.vue'),
      },
      {
        path: 'home/detail/:id',
        name: 'Influencer Details',
        component: () => import('@/views/Home/InfluencerDetail.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      // {
      //   path: 'trending',
      //   name: 'Trending',
      //   component: () => import('@/views/pages/Trending'),
      // },
      {
        path: 'influencers',
        name: 'Influencers',
        component: () => import('@/views/Influencers/Index'),
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: () => import('@/views/Campaigns/Index'),
      },
      {
        path: 'campaign/detail/:id',
        name: 'Campaign Detail',
        component: () => import('@/views/Campaigns/Detail'),
      },
      {
        path: '/tsheet',
        name: 'Tsheets',
        component: () => import('@/views/Tsheet/Index.vue'),
      },
      {
        path: '/tsheet/detail/:id',
        name: 'Tsheet Detail',
        component: () => import('@/views/Tsheet/Detail.vue'),
      },
      {
        path: '/tsheet/tracking/:id',
        name: 'Tracking',
        component: () => import('@/views/Tsheet/Tracking.vue'),
      },
      {
        path: '/tsheet/integration/:id',
        name: 'Integration',
        component: () => import('@/views/Tsheet/Integration.vue'),
      },
      {
        path: '/tsheet/create/1',
        name: 'T-Sheet Create 1',
        component: () => import('@/views/Tsheet/NewSheet1.vue'),
      },
      // {
      //   path: 'campaign/create/1',
      //   name: 'Campaign Create 1',
      //   component: () => import('@/views/Campaigns/NewCampaign1'),
      // },
      // {
      //   path: 'affiliate',
      //   name: 'Affiliate',
      //   component: () => import('@/views/Affiliates/Index.vue'),
      // },
      // {
      //   path: 'sales',
      //   name: 'Sales',
      //   component: () => import('@/views/Sales/Index.vue'),
      // },
      // {
      //   path: 'programs',
      //   name: 'Programs',
      //   component: () => import('@/views/Programs/Index'),
      // },
      // {
      //   path: 'program/detail/:id',
      //   name: 'Programs Detail',
      //   component: () => import('@/views/Programs/Detail'),
      // },
      // {
      //   path: 'program/create/1',
      //   name: 'Programs Create 1',
      //   component: () => import('@/views/Programs/NewProgram1'),
      // },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('@/views/Chat/Index'),
      },
      {
        path: 'admin/events',
        name: 'All Events',
        component: () => import('@/views/Admin/Index'),
      },
      {
        path: 'notification',
        name: 'Notification',
        component: () => import('@/views/Notification/Index.vue'),
      },
      {
        path: 'notif',
        name: 'Notif',
        component: () => import('@/views/Notif'),
      },
    ],
  },
  // {
  //   path: '/messages',
  //   name: 'Messages',
  //   component: () => import('@/layouts/Messages'),
  // },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
