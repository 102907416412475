<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column vh-100">
      <AppHeader />
      <div class="body flex-grow-1 h-100 tbcustom">
        <!-- <CContainer lg> -->
        <router-view />
        <!-- </CContainer> -->
      </div>
      <!-- <AppFooter /> -->
    </div>
  </div>
</template>
<script>
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { initializeTwilioClient } from '@/services/chatClient.js'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
  },
  mounted() {
    console.log('mounted default')
    if (localStorage.getItem('login')) {
      initializeTwilioClient()
    }
  },
}
</script>

<style scoped>
.tbcustom {
  background-color: var(--black2);
  /* padding: 20px 0 0 10px; */
  border-top: solid 1px var(--grey2);
  overflow: overlay;
}
</style>
